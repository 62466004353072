<template>
    <li class="no-gutters row mb-2">
        <div class="col-auto pe-0">
            <img
                class="list-item-icon"
                src="@/assets/images/global/question-circle.svg"
                alt="Close quickly"
            >
        </div>
        <div class="col text-start ps-0">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.helpListItem.title') }}
            </p>
            <p
                v-if="!showFeedbackSent"
                class="text-muted"
            >
                We are here to help. Please <a
                    href="#"
                    @click.prevent="showFeedbackBox = true"
                > send us a message </a> or call <a :href="`tel:${contactInfo.phone}`">{{ contactInfo.phone }}</a>.
            </p>
            <div
                v-else
                class="text-muted"
            >
                {{ $t('components.onboarding.helpListItem.altSubTitle') }}
            </div>
            <feedback-box
                v-show="showFeedbackBox"
                @feedback-sent="handleFeedbackSent"
            />
        </div>
    </li>
</template>

<script>
    import { contact_info } from '@/utils/contact-info-dictionary'
    import FeedbackBox from '../FeedbackBox'
    export default {
        name: 'HelpListItem',
        components: {
            'feedback-box': FeedbackBox,
        },
        data: function () {
            return {
                showFeedbackBox: false,
                showFeedbackSent: false,
            }
        },
        methods: {
            handleFeedbackSent: function () {
                this.showFeedbackBox = false
                this.showFeedbackSent = true
            },
        },
        computed: {
            contactInfo: function () {
                return contact_info
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
