<template>
    <onboarding-layout
        :header="false"
        :loading="loading"
    >
        <img
            src="@/assets/images/notary/cameraNotWorking.svg"
            width="200px"
            alt="Camera Not Working"
        >
        <h5
            class="fw-light"
            v-html="this.message"
        />
        <div class="d-grid">
            <a
                class="btn btn-secondary mt-3"
                :href="'tel:' + this.supportPhone"
            > Still Not Working? </a>
        </div>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { sendApplicantTextWithNotarizationLink } from '@/services/schedulingApi'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'

    export default {
        components: {
            'onboarding-layout': OnboardingLayout,
        },
        data() {
            return {
                supportPhone: contact_info.phone.replace(/[^\d]/g, ''),
                phoneNumber: null,
                loading: true,
            }
        },
        computed: {
            message() {
                return `We could not find a webcam to use. We sent a link to ${this.phoneNumber} to try on your mobile phone.`
            },
        },
        mounted: async function () {
            appSessionStorage.setItem(localStorageKey.clearStorageOnNavigation, 'true')

            logger.log("Sending notary link to user's phone...")
            try {
                const resp = await sendApplicantTextWithNotarizationLink()

                this.phoneNumber = resp.data.payload.phoneNumber
                logger.log(`Notary link sent! Got back phone number ${this.phoneNumber}`)

                this.loading = false
                this.$logEvent('view_notary_requires_av')
            } catch (e) {
                ApiErrorHandler(e)
            }
        },
    }
</script>
