<template>
    <div>
        <p :class="`section-header mb-2 ${variant}`">
            <slot />
        </p>
        <div
            v-if="underline"
            class="underline"
        />
    </div>
</template>

<script>
    export default {
        name: 'SectionHeader',
        props: {
            // variant can be [text-primary, text-secondary, text-muted, etc]
            variant: {
                type: String,
                default: 'text-muted',
            },
            underline: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped>
    .underline {
        border-bottom: 3px solid black;
        width: 30px;
        /* display: inline-block; */
    }
</style>
