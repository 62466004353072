<template>
    <div class="layout p-0">
        <div
            v-if="loading"
            class="vh-100 px-1"
        >
            <div v-if="errorText">
                <div
                    class="alert-container pt-3"
                    v-show="errorText.length > 0"
                >
                    <div
                        class="alert alert-warning text-center"
                        role="alert"
                    >
                        <span v-html="errorText" />
                    </div>
                </div>
            </div>
            <loading-indicator :title="loadingTitle" />
        </div>
        <div
            v-show="!loading"
            class="onboarding-layout-container"
        >
            <div class="left-container">
                <div class="left-content">
                    <div class="left-header">
                        <img
                            src="@/assets/images/global/aven.svg"
                            class="logo"
                            width="71"
                            alt="Go Home"
                        >
                        <section-header
                            class="small text-muted"
                            v-if="stepTitle"
                        >
                            {{ stepTitle }}
                        </section-header>
                    </div>
                    <div
                        class="alert-container mb-3"
                        v-show="errorText.length > 0"
                    >
                        <div
                            class="alert alert-warning text-center"
                            role="alert"
                        >
                            <span v-html="errorText" />
                        </div>
                    </div>
                    <div
                        v-show="!loading && header && (title || subTitle)"
                        class="onboarding-layout-header mb-4"
                    >
                        <h5
                            class="fw-light"
                            v-if="title"
                            v-html="title"
                        />
                        <p
                            v-if="subTitle"
                            class="fw-bold mt-5 my-5 text-center"
                            id="subTitle"
                            @click="submitSubtitleClick"
                        >
                            <span
                                style="cursor: pointer"
                                class="clickable-text"
                                v-html="subTitle"
                            />
                        </p>
                    </div>
                    <slot />
                </div>
            </div>
            <div class="right-container">
                <div class="right-content">
                    <slot name="sidebar">
                        <!-- default styling-->
                        <ul class="list-unstyled list-row">
                            <help-list-item />
                        </ul>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import HelpListItem from '../components/onboarding/HelpListItem'
    import SectionHeader from '../components/SectionHeader'

    export default {
        name: 'OnboardingLayout',
        props: {
            header: { type: Boolean, default: true },
            stepTitle: { type: String, default: '' },
            title: { type: String },
            subTitle: { type: String },
            subTitleClick: { type: Function },
            loading: { type: Boolean },
            loadingTitle: { type: String, default: 'Loading...' },
            errorText: { type: String, default: '' },
        },
        components: {
            'section-header': SectionHeader,
            'help-list-item': HelpListItem,
            'loading-indicator': LoadingIndicator,
        },
        methods: {
            submitSubtitleClick: function () {
                this.$emit('sub-title-click')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/layouts/onboarding';
</style>
