import { httpClient } from '@/utils/http-client'
import { logger } from '@/utils/logger'

export const getIsAppointmentAlreadyScheduled = async () => {
    return await httpClient.get('/remoteNotarization/scheduling/getIsAppointmentAlreadyScheduled')
}

export const getImmediateTimeSlot = async () => {
    return await httpClient.get('/remoteNotarization/scheduling/getImmediateTimeSlot')
}

export const getPhoneNumber = async () => {
    return await httpClient.get('/remoteNotarization/scheduling/getPhoneNumber')
}

export const getAvailableAppointments = async () => {
    logger.info(`Retrieving available appointments`)
    return await httpClient.get('/remoteNotarization/scheduling/getAvailableAppointments')
}

export const confirmAppointment = async (timeSlot: { start: string; end: string }, timeZone: string) => {
    logger.info(`Confirming selected appointment: ${JSON.stringify(timeSlot)}`)
    return await httpClient.post('/remoteNotarization/scheduling/confirmAppointment', {
        timeSlot,
        timeZone,
    })
}

export const sendApplicantTextWithNotarizationLink = async () => {
    return await httpClient.post('/remoteNotarization/scheduling/sendApplicantTextWithNotarizationLink')
}

export const validateSession = async () => {
    return await httpClient.get('/remoteNotarization/scheduling/validateSession')
}

export const getSessionLink = async () => {
    logger.info('Retrieving notary session link')
    return await httpClient.get('/remoteNotarization/scheduling/getSessionLink')
}

export const getSecondarySessionLink = async () => {
    logger.info('Retrieving secondary session link')
    return await httpClient.get('/remoteNotarization/scheduling/getSecondarySessionLink')
}
